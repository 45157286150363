import React from 'react';

const Gallery = ({ images, folderName }) => {
  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const imageChunks = chunkArray(images, 3);

  return (
    <>
      <div className="container">        
            {imageChunks.map((chunk, index) => (
              <div className={index === 0 ? 'item active' : 'item'} key={index}>
                <div className="row">
                  {chunk.map((item, subIndex) => (
                    <div
                      key={index}
                      className="col-lg-4 col-md-4 col-sm-12 col-xs-12 marginb20">
                                           
                      <a data-fancybox={`${folderName} gates`} href={`${folderName}/${item.image}`}>
                        <img
                          key={subIndex}
                          src={`${folderName}/thumb/${item.image}`}
                          alt={`Gate ${index * 6 + subIndex}`}
                        />
                      </a>
                      <h5 className="yellowsubhead">{item.caption}</h5>
                    </div>
                  ))}
                </div>
              </div>
            ))}          
            <p>&nbsp;</p>         
      </div>
    </>
  );
};

export default Gallery;

