import React from 'react';
import ThumbnailSlider from './ThumbnailSlider';
import OptionsAvailable from './OptionsAvailable';
import PowerOptions from './PowerOptions';
import SolarPowerLight from './SolarPowerLight';
import ServiceArea from './ServiceArea';
import { Link } from 'react-router-dom';

const SlidingGate = () => { 
  const thumbimages = [
    'slider1.jpg',
    'slider2.jpg',
    'slider3.jpg',
    'slider1.jpg',
    'slider2.jpg',
    'slider3.jpg'
  ];
  

  
  return (
    <>
     <div className="section fp-auto-height">
       <div className="container">
         <div className="yellowhead colorhead text-center">
           <img data-src="heading-double.png" className="marginauto img-responsive lazy-loaded" alt="Slider Gate" src="heading-sliding.png" />          
           <h2>Sliding Gates</h2>
         </div>
         <div className="text-center">
           <p>Sliding gates can be of two types, Track mounted so the wheels on the gate run on a track across the driveway which is ideal for domestic and concrete driveways, a concrete beam can be put in for the track if needed. The other application is the Cantilever type that has no track over the drive, it suspends the gate across the driveway via rollers fixed to the ground, ideal for rural application's or uneven ground, gates locate into a striker post on the closed side for stability.</p>
           <p>Automation is available for both systems, visitors access can be via push button or keypads on a post.</p>
           <p>Custom slider gates also made to suit your needs, painted or powder coated available.</p><p>&nbsp;</p>
         </div>
         </div>
         <center><ThumbnailSlider images={thumbimages} folderName="slider" /></center>
         <div className="container"><div className="row"><div className="col-lg-12 col-md-12 col-sm-2 col-xs-2">&nbsp;</div><div className="col-lg-12 col-md-12 col-sm-8 col-xs-8"><center><Link className="anchorprop slidinggateoption" to="/options"><img src="icon-sliding-options.png" className="anchoricon" alt="Sliding Options"/><span className="spacer">Options</span></Link></center></div><div className="col-lg-12 col-md-12 col-sm-2 col-xs-2">&nbsp;</div></div></div>
       </div>
         <OptionsAvailable />
       <PowerOptions />
       <SolarPowerLight />
       <ServiceArea />
    </>
  );
};

export default SlidingGate;

