import React from 'react';

const ThumbnailSlider = ({ images,folderName }) => {
  
  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };
  

  const imageChunks = chunkArray(images, 6);
  

  return (
  <>
     <div className="container">
    <div id="myCarousel" className="carousel slide">
    <div className="carousel-inner">
       
               
               {imageChunks.map((chunk, index) => (                  
                   <div className={index === 0 ? 'item active' : 'item'}  key={index}>
	           <div className="row">			
			  {chunk.map((image, subIndex) => (
			   <div key={index} className="col-lg-2 col-md-2 col-sm-4 col-xs-4">
			       <a data-fancybox="{`${folderName} gates}`" href={`${folderName}/${image}`}>
				 <img key={subIndex} src={`${folderName}/thumb/${image}`} alt={`Gate ${index * 6 + subIndex}`} />
			       </a>
			    </div>
			   
			  ))}
		    </div> 
		    </div>
		    
               ))}
               
                 <a className="left carousel-control" href="#myCarousel" data-slide="prev"><span className="glyphicon glyphicon-chevron-left"></span><span className="sr-only">Previous</span></a>
                <a className="right carousel-control" href="#myCarousel" data-slide="next"><span className="glyphicon glyphicon-chevron-right"></span><span className="sr-only">Next</span></a>
                <p>&nbsp;</p>
                
            </div>
        </div>
     </div>
     </>
        
  
  );
};

export default ThumbnailSlider;  
