import React from 'react';

const CustomGate = () => { 
  
  return (
    <>
     <div className="section fp-auto-height cgcontent">
       <div className="container">
       <div className="row">
         <div className="greenhead colorhead text-center">
           <img data-src="heading-single.png" className="marginauto img-responsive lazy-loaded" alt="Custom Gate" src="heading-custom.png" />          
           <h2>Custom Gates</h2>
         </div>
         <div className="text-center customsubheader margin30"><h3>Looking for a new gate?</h3></div>
         <div className="text-center">
           <p>We can Custom design and manufacture a new gate to suit your needs, whether it's steel or timber we can source something that will suit your needs. We have Custom made to order reproduction cast joint gates available , old Sunshine Mckay replica. Standard farm gates in stock.</p>
           <p>Complete new entrance way are also done to your requirements.</p>          
         </div>
         </div>  
        
         <div className="row margint50"><div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">&nbsp;</div><div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"><a className="greenbutton" href="/contact-us">Contact us today for a quote</a></div><div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">&nbsp;</div></div>      
       </div>
        </div>
    </>
  );
};

export default CustomGate;

