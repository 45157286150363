import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => { 
  
  return (
    <>
    <div className="section fp-auto-height" id="section13">
        <div className="container copyright">
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">© Copyright {new Date().getFullYear()}.  <Link to="/">Victorian Automatic Solar Gates</Link>. All Rights Reserved. <Link to="/privacy-policy">Privacy Policy</Link>. </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">Website Developed by <a href="http://mrgraphics.com.au/" target="_blank" rel="noreferrer">MRGraphics</a>.</div>
        </div>       
       </div>
    </>
  );
};

export default Footer;
