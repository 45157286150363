import React from 'react';
import CarouselComponent from './CarouselComponent';
import OptionsAvailable from './OptionsAvailable';
import PowerOptions from './PowerOptions';
import SolarPowerLight from './SolarPowerLight';
import ServiceArea from './ServiceArea';

function Home() {
  const images = [
    'vasg_banner_01.jpg',
    'vasg_banner_01_02.jpg',
    'vasg_banner_01_03.jpg'
  ];
  
  const numImages = images.length;
  
  return (
    <>
    <CarouselComponent images={images} numImages={numImages} />
    <div className="section fp-auto-height">
       <div id="vicsolargate" className="container">
         <div className="text-center">
           <h2>Victorian Automatic Solar Gates</h2>
         </div>
         <div className="text-center">
           <p>Whether you need a basic gate operator that just opens and closes the gate for convenience or you want a gate opener with all the options available  ie security,  lights, alarms, intercom, locks ,we can customize a gate systems to suit your needs. Our gate systems are strong , safe , and reliable, they can be operated by either SOLAR, MAINS POWERED, or LOW VOLTAGE.  Most of our gate systems are AUSTRALIAN MADE.</p><p>If you live in a bushy area and have no sun at the gate we can move the solar panel  to a remote location  to give you the charge needed to operate the system,  we also have a battery bank system  for the property  that has very little access to good sunlight, the battery can operate the gate for a period of time usually months, depending on the use and can be taken back to the house and charged ready for the next cycle of use. We are a business based in Central Victoria  that services most of Victoria  and lower NSW. If we don't service your area we have D.I.Y kit sent Australia Wide. </p><p>&nbsp;</p>
         </div>
       
         <div className="row">
           <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 featuring">
		   <img src="home-single-double-kits.png" className="img-responsive text-center marginauto" alt="SINGLE & DOUBLE KITS" />
		   <h3 className="text-center">SINGLE &amp; DOUBLE KITS</h3>		  
           </div>
           <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 featuring">
		   <img src="home-security.png" className="img-responsive text-center marginauto" alt="SAFE & SECURE" />
		   <h3 className="text-center">SAFE &amp; SECURE</h3>
		   
           </div>
           <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 featuring">
             <img src="home-strong.png" className="img-responsive text-center marginauto" alt="STRONG" />
             <h3 className="text-center">STRONG</h3>
            
           </div>
         </div>
         <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 featuring">
               <img src="home-solar.png" className="img-responsive text-center marginauto" alt="RELIABLE" />
               <h3 className="text-center">RELIABLE</h3>
            
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 featuring">
              <img src="home-security.png" className="img-responsive text-center marginauto" alt="SOLAR" />
              <h3 className="text-center">SOLAR</h3>
           
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 featuring">
              <img src="home-solar-lights.png" className="img-responsive text-center marginauto" alt="SOLAR LIGHTS" />
              <h3 className="text-center">SOLAR LIGHTS</h3>
              
            </div>
         </div>
         </div>
         </div>
    <div className="proudlypresents">
	    <div className="container">
		    <div className="text-center">
		    <img src="home-australian-source.png" className="img-responsive marginauto" alt="Australian made" />
		    </div>
		    <div className="text-center marginauto">
		    </div>
	    </div>
    </div>
     <OptionsAvailable />
       <PowerOptions />
       <SolarPowerLight />
       <ServiceArea />
    </>
  );
}

export default Home;

