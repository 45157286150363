import React from 'react';
import ThumbnailSlider from './ThumbnailSlider';
import OptionsAvailable from './OptionsAvailable';
import PowerOptions from './PowerOptions';
import SolarPowerLight from './SolarPowerLight';
import ServiceArea from './ServiceArea';
import { Link } from 'react-router-dom';
const DoubleGate = () => { 
  const thumbimages = [
    'double1.jpg',
    'double2.jpg',
    'double3.jpg',
    'double4.jpg',
    'double5.jpg',
    'double6.jpg',
    'double7.jpg',
    'double8.jpg',
    'double9.jpg',
    'double10.jpg',
    'double11.jpg',
    'double12.jpg'
  ];
  

  
  return (
    <>
     <div className="section fp-auto-height">
       <div className="container">
         <div className="bluehead colorhead text-center">
           <img data-src="heading-double.png" className="marginauto img-responsive lazy-loaded" alt="Double Gate" src="heading-double.png" />          
           <h2>Double Solar Gates</h2>
         </div>
         <div className="text-center">
           <p>Most Double gates are able to be automated , we use either a ground stopper or a striker tab on the gate to hold the gates together. Double gates need a trench dug across the drive to get the wire and conduit from the control box to the gate drive motor on the opposite side, visitors access can be via push button or keypads on a post, video audio intercom system to the house ,ie see who is at the gate and talk to them and open the gate from the house if you wish. Gate alarms, safety beam, night lights, long range remote all available with certain systems.</p>
           <p>AUSTRALIAN MADE 12 and 24 volt system.</p>
           <p>Custom Gates and entrances made to your requirements.</p><p>&nbsp;</p>
         </div>
         </div>
         <ThumbnailSlider images={thumbimages} folderName="double" />
         <div className="container"><div className="row"><div className="col-lg-12 col-md-12 col-sm-2 col-xs-2">&nbsp;</div><div className="col-lg-12 col-md-12 col-sm-8 col-xs-8"><center><Link className="anchorprop doublegateoption" to="/options"><img src="icon-double-options.png" className="anchoricon" alt="Double Options" /><span className="spacer">Options</span></Link></center></div><div className="col-lg-12 col-md-12 col-sm-2 col-xs-2">&nbsp;</div></div></div>
       </div>
       <OptionsAvailable />
       <PowerOptions />
       <SolarPowerLight />
       <ServiceArea />
    </>
  );
};

export default DoubleGate;

