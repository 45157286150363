import React from 'react';

const PowerOptions = () => { 
  
  return (
    <>
<div className="container">
   <div className="greenhead colorhead text-center">
      <img data-src="heading-power.png" className="marginauto img-responsive lazy-loaded" alt="power" src="heading-power.png" />
        <h2>Power options</h2>
   </div>
   <div className="row">
      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="powerbox">
            <img className="img-responsive marginauto lazy-loaded" data-src="power-solar.png" alt="solar" src="power-solar.png"/>
            <h3 className="text-center greenhead">Solar</h3>
            <div className="text-center"></div>
          </div>
     </div>
     <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
         <div className="powerbox">
            <img className="img-responsive marginauto lazy-loaded" data-src="power-low-voltage.png" alt="low voltage" src="power-low-voltage.png" />
            <h3 className="text-center greenhead">Low Voltage</h3>
            <div className="text-center"></div>
         </div>
     </div>
     <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
         <div className="powerbox">
            <img className="img-responsive marginauto lazy-loaded" data-src="power-mains.png" alt="mains" src="power-mains.png"/>
            <h3 className="text-center greenhead">Mains</h3>
            <div className="text-center"></div>
         </div>
    </div>
   </div>
 </div>
   </>
  );
};

export default PowerOptions;
