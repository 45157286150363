import React from 'react';
import { Link } from 'react-router-dom';

const CarouselComponent = ({ images, numImages }) => {
  return (
  
    <div className="section fp-auto-height" id="section1">
     <div id="bannersect">
       <div className="container">
          <div className="col-lg-8 col-md-8 col-sm-7 col-xs-7 mobctrl">
             <div id="myHomeCarousel" className="carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                     {images.map((image, index) => (
                         <li data-target="#myHomeCarousel" data-slide-to={index} className={index === 0 ? 'active' : ''}></li>
                      ))}
                </ol>
                <div className="carousel-inner">                                    
		      {images.map((image, index) => (
			<div className={`item ${index === 0 ? 'active' : ''}`}>
			  <img
			    className="img-responsive"
			    src={image}
			    alt={`Slide ${index + 1}`}
			  />
			</div>
		      ))}
                              
                </div>
             </div>
         </div>
         <div className="col-lg-4 col-md-4 col-sm-5 col-xs-5 mobctrl">
           <div className="lightbluebox">
             <h2>For all types of rural, light industiral and domestic gates</h2>
             <Link to="/single-gate"  className="learnmorebutton text-center">Learn more</Link>
             <Link to="/contact-us" className="contactusbutton text-center">Contact us</Link>
           </div>
         </div>
     </div>
     </div>
     </div>
    
  );
};

export default CarouselComponent;

