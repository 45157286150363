import React from 'react';
import { NavLink } from 'react-router-dom';



function Navbar() {
  return (
    <div className="container">
    <nav className="navbar navbar-default">      
      <div className="navbar-header">
         <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-collapse-1">
           <span className="sr-only">Toggle navigation</span><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button>
       </div>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="nav navbar-nav">
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" exact to="/">HOME</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="/single-gate">SINGLE GATE</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="/double-gate">DOUBLE GATE</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="/sliding-gate">SLIDING GATE</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="/custom-gate">CUSTOM GATES</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="/diy-kits">DIY KITS</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="/contact-us">CONTACT US</NavLink>
          </li>
          {/* Add more nav items here */}
        </ul>
      </div>
    </nav>
    </div>
  );
}

export default Navbar;

