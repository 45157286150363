import React, { useState } from 'react';
import axios from 'axios'; // Import Axios
import ReCAPTCHA from 'react-google-recaptcha';

const ContactUs = () => {
    const [firstName, setFirstName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [gateMode,setGateMode] = useState({
    newgate: false,
    existinggate: false
  });

  const [gateTypes, setGateTypes] = useState({
    single: false,
    double: false,
    sliding: false,
   });
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [fileUpload, setFileUpload] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null);

  const [formErrors, setFormErrors] = useState({});
  const allowedFileExtensions = ['.pdf', '.txt', '.docx','.doc', '.ppt','.pptx','.jpg','.png','.gif'];
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);    
  };
  const [message, setMessage] = useState('');
  const [isMessageVisible, setIsMessageVisible] = useState(false);


  const validateForm = () => {
    const errors = {};

    // First Name validation
    if (firstName.trim() === '') {
      errors.firstName = 'First Name is required';
    }
    
    if (firstName.trim() === '') {
      errors.contactNumber = 'Contact Number is required';
    }

    // Email Address validation
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailPattern.test(emailAddress)) {
      errors.emailAddress = 'Invalid Email Address';
    }

    // Gate Selection validation
    if (!gateMode.newgate && !gateMode.existinggate) {
      errors.gateSelection = 'Select at least one gate type';
    }
 

   
    if (fileUpload) {     
      for (let i = 0; i < fileUpload.length; i++) {      
	      const file = fileUpload[i];
	      const fileExtension = file.name.split('.').pop().toLowerCase();
	      if (!allowedFileExtensions.includes(`.${fileExtension}`)) {
		errors.fileUpload = 'Invalid file type. Allowed types: pdf, txt, docx';
		break; // Stop checking further files if one is invalid
	      }
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form
    if (!validateForm()) {
      return;
    }

    // Define variables based on the checkbox values
    const single = gateTypes.single ? 'Yes' : 'No';
    const double = gateTypes.double ? 'Yes' : 'No';
    const sliding = gateTypes.sliding ? 'Yes' : 'No';
    
    const newGate = gateMode.newgate ? 'Yes' : 'No';
    const existingGate = gateMode.existinggate ? 'Yes' : 'No';

    // Create a FormData object to send form data
    const formData = new FormData();
    formData.append('firstname', firstName);
    formData.append('emailaddress', emailAddress);
    formData.append('contactnumber', contactNumber);
    formData.append('newgate', newGate);
    formData.append('existinggate', existingGate);
    formData.append('single', single);
    formData.append('double', double);
    formData.append('sliding', sliding);
    formData.append('additionalinformation', additionalInfo);
    formData.append('g-recaptcha-response',captchaValue);
   
    if (fileUpload) {
      for (let i = 0; i < fileUpload.length; i++) {      
        const file = fileUpload[i];      
        formData.append(`fileupload_${i}`, file); // Append each file with a unique key
      }
    }
      
    // Make an Axios request to send the form data to the server
    axios
      .post('/ajax.php', formData)
      .then((response) => {
        if (response.data.success) {
          // Clear the form data on successful submission
          setFirstName('');
          setEmailAddress('');
          setContactNumber('');
          setGateMode({newgate:false,existinggate:false});
          setGateTypes({
            single: false,
            double: false,
            sliding: false,
          });
          setAdditionalInfo('');
          setFileUpload(null);
          setCaptchaValue(null);

          // Set success message and make it visible
          setMessage('Email sent successfully.');
          setIsMessageVisible(true);
        } else {
          // Set failure message and make it visible
          setMessage('Email sending failed.');
          setIsMessageVisible(true);
        }
      })
      .catch((error) => {
        // Handle any errors that occur during the request
        console.error('Error:', error);
      });
  };

  


  return (
  <div id="contactus">
  <div className="container">
     <div className="bluehead colorhead text-center">
       <img data-src="heading-maps.png" alt="maps" className="marginauto img-responsive lazy-loaded" src="heading-maps.png" />
       <h2>Contact us</h2>
     </div>
     <div className="row">
       <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"><div>
	       <div className="contactusinline">
		 <img data-src="icon-phone.png" alt="phone" className="img-responsive lazy-loaded" src="icon-phone.png" />
	       </div>
	       <div className="contactusinline"><b>Brett Gorman</b> 0417 355 889</div>
	       <div>
		 <div className="contactusinline">
		   <img data-src="icon-mail.png" alt="email" className="img-responsive lazy-loaded" src="icon-mail.png" />
		 </div>
		 <div className="contactusinline">
		   <a href="mailto:info@vasolargates.com.au">info@vasolargates.com.au</a>
		 </div>
	       </div>
		 <div className="bottomfb">
		   <a href="https://www.facebook.com/Victorian-Automatic-Solar-Gates-734748983338653/" target="_blank" className="interactive" rel="noreferrer"></a>
		 </div>
		 </div>
		  <form id="contactform" encType="multipart/form-data"  method="POST"  onSubmit={handleSubmit}>
      {/* First Name */}
      <input
        type="text"
        id="firstname"
        name="firstname"
        placeholder="Contact Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      {formErrors.firstName && <span className="help-block">{formErrors.firstName}</span>}

      {/* Email Address */}
      <input
        type="email"
        id="emailaddress"
        name="emailaddress"
        placeholder="Email Address"
        value={emailAddress}
        onChange={(e) => setEmailAddress(e.target.value)}
      />
      {formErrors.emailAddress && <span className="help-block">{formErrors.emailAddress}</span>}
      
      {/* Contact Number */}
  <input
    type="tel"                // Use type="tel" for telephone numbers
    id="contactnumber"
    name="contactnumber"
    placeholder="Contact Number"
    value={contactNumber}
    onChange={(e) => setContactNumber(e.target.value)}
  />
  {formErrors.contactNumber && <span className="help-block">{formErrors.contactNumber}</span>}


      {/* Gate Selection */}
      <div className="tbmargin">Is this a new or existing gate (or both)?</div>
      <label>
        <span className="checkborder"><input
          type="checkbox"
          name="newgate"
          checked={gateMode.newgate}
          onChange={() => setGateMode({...gateMode, newgate:!gateMode.newgate})}
        /></span>
        <span className="cbcontent">New Gate</span>
      </label>
      <label>
        <span className="checkborder"><input
          type="checkbox"
          name="existinggate"
          checked={gateMode.existinggate}
          onChange={() => setGateMode({...gateMode, existinggate:!gateMode.existinggate})}
        /></span>
        <span className="cbcontent">Existing Gate</span>
      </label>
      {formErrors.gateSelection && <span className="help-block">{formErrors.gateSelection}</span>}

      {/* Gate Types */}
      <div className="tbmargin">Is it a single or double gate (or both)?</div>
      <label>
        <span className="checkborder"><input
          type="checkbox"
          name="single"
          checked={gateTypes.single}
          onChange={() => setGateTypes({ ...gateTypes, single: !gateTypes.single })}
        /></span>
        <span className="cbcontent">Single</span>
      </label>
      <label>
        <span className="checkborder"><input
          type="checkbox"
          name="double"
          checked={gateTypes.double}
          onChange={() => setGateTypes({ ...gateTypes, double: !gateTypes.double })}
        /></span>
        <span className="cbcontent">Double</span>
      </label>
      <label>
        <span className="checkborder"><input
          type="checkbox"
          name="sliding"
          checked={gateTypes.sliding}
          onChange={() => setGateTypes({ ...gateTypes, sliding: !gateTypes.sliding })}
        /></span>
        <span className="cbcontent">Sliding</span>
      </label>
      {/* Additional Information */}
      <textarea
        name="additionalinformation"
        id="additionalinfo"
        placeholder="Additional Information"
        value={additionalInfo}
        onChange={(e) => setAdditionalInfo(e.target.value)}
      ></textarea>
      <div className="cinline fullwidth">
        <label htmlFor="fileupload">File Upload</label>
        <input
          type="file"
          name="fileupload"
          accept=".pdf, .txt, .docx,.doc, .ppt,.pptx,.jpg,.png"
          onInput={(e) => {console.log("Target files - ");console.log(e.target.files);setFileUpload(e.target.files);console.log("File upload-"+fileUpload);}}
        />
        <br />
        {formErrors.fileUpload && <span className="help-block">{formErrors.fileUpload}</span>}
      </div>
       {/* Google captcha */}
        <ReCAPTCHA
          sitekey="6LcGMg8bAAAAAPRG-A8t4TNnLs_-uFiHt2qdoHYc"
          onChange={handleCaptchaChange}
        />      
       <br/>
       {formErrors.captchaValue && <span className="help-block">{formErrors.captchaValue}</span>}             
       
      <input type="submit" className="submitbutton" value="Submit Enquiry" />
      {isMessageVisible && <div className="messagesend alert alert-success">{message}</div>}
    </form>
       </div>
         <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
		   <div className="fb-page fb_iframe_widget" data-href="https://www.facebook.com/Victorian-Automatic-Solar-Gates-734748983338653/" data-tabs="timeline" data-width="530" data-height="720" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true" fb-xfbml-state="rendered" fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=508&amp;height=720&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FVictorian-Automatic-Solar-Gates-734748983338653%2F&amp;locale=en_GB&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=530">
		   <span style={{verticalAlign: 'bottom', width: '500px', height: '720px'}}>
			   <iframe name="f38bb684f99e46c" width="530px" height="720px" data-testid="fb:page Facebook Social Plugin" title="fb:page Facebook Social Plugin" frameBorder="0" allowtransparency="true" allowFullScreen={true} scrolling="no" allow="encrypted-media" src="https://www.facebook.com/v2.9/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df3f48a673edfc8%26domain%3Dvasolargates.com.au%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fvasolargates.com.au%252Ff1c831fa40ec53c%26relation%3Dparent.parent&amp;container_width=508&amp;height=720&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FVictorian-Automatic-Solar-Gates-734748983338653%2F&amp;locale=en_GB&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=530" style={{border: 'none', visibility: 'visible', width: '500px', height: '720px',}} className="">
			   </iframe>
		   </span>
		   </div>
           </div>
        </div>
     </div>
           <p>&nbsp;</p><p>&nbsp;</p>
 </div>
   
  );
};

export default ContactUs;

