import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopHeader from './components/TopHeader';
import Navbar from './components/Navbar';
import Home from './components/Home';
import SingleGate from './components/SingleGate';
import DoubleGate from './components/DoubleGate';
import SlidingGate from './components/SlidingGate';
import CustomGate from './components/CustomGate';
import DiyKits from './components/DiyKits';
import OptionsAvailable from './components/OptionsAvailable';
import ContactUs from './components/ContactUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import Footer from './components/Footer';
import './App.css';


function App() {
  return (
    <Router>
      <div className="topheader">
         <>
         <TopHeader />
         <Navbar />   
           
         </>
      </div>
      <Routes>
        <Route path="/"  element={<Home />} />     
        <Route path="/single-gate"  element={<SingleGate />} />    
        <Route path="/double-gate"  element={<DoubleGate />} />   
        <Route path="/sliding-gate"  element={<SlidingGate />} />  
        <Route path="/custom-gate"  element={<CustomGate />} />  
        <Route path="/diy-kits"  element={<DiyKits />} />
        <Route path="/options"  element={ <OptionsAvailable />} />
        <Route path="/contact-us"  element={ <ContactUs />} />
        <Route path="/privacy-policy"  element={ <PrivacyPolicy />} />
        {/* Add more routes for other pages */}
       </Routes>
        <Footer /> 
    </Router>
     
  );
}

export default App;

