import React from 'react';
import { Link } from 'react-router-dom';

const ServiceArea = () => { 
  
  return (
    <>
<div className="section fp-auto-height" id="section11">
	<div className="container">
		<h2 className="installationheader">Installation available to suit almost every<br/> Commercial, Industrial, Rural and Domestic applications</h2>
			<div className="row">
				<div className="col-lg-4 col-md-4 col-xs-12 col-sm-12">
				   <img data-src="install-domestic.png" alt="domestic" className="img-responsive marginauto lazy-loaded" src="install-domestic.png" />
				</div>
				<div className="col-lg-4 col-md-4 col-xs-12 col-sm-12">
				   <img data-src="install-rural.png" alt="rural" className="img-responsive marginauto lazy-loaded" src="install-rural.png" />
				</div>
				<div className="col-lg-4 col-md-4 col-xs-12 col-sm-12">
				  <img data-src="icon-commercial.png" alt="commercial" className="img-responsive marginauto lazy-loaded" src="icon-commercial.png" />
				</div>
			</div>
		<p>&nbsp;</p><p>&nbsp;</p>
	</div>
</div>

<div className="section fp-auto-height" id="section12">
	<div className="container">
		<div className="greenhead colorhead text-center">
			<img data-src="heading-map.png" alt="map" className="marginauto img-responsive lazy-loaded" src="heading-map.png" />
			<h2>Service Area</h2>
		</div>
		<div className="text-center customsubheader margin30"><h3>Need Installation?</h3></div>
		<div className="text-center margin30">Professional installation available in most areas, please contact us for a quote!</div>
		<div className="row">
			<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">&nbsp;</div>
			<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
			        <Link className="greenbutton singlegateoption" to="/diy-kits">Outside our service area? We ship DIY kits Australia Wide.</Link>
			</div>
			<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">&nbsp;</div>
		</div>
		<div className="row">
			<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<iframe title="contactus" src="https://www.google.com/maps/d/embed?mid=1JqgsGuH8KIueN5EzYQG2-qVyEZ8" width="100%" height="320" className="margint30 mapcontrol"></iframe>
			</div>
		</div>
	</div><p>&nbsp;</p><p>&nbsp;</p>
</div>
       </>
  );
};

export default ServiceArea;
