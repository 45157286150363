import React from 'react';

const TopHeader = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-5">
          <img src="/vasg-header-logo.png" className="img-responsive logo" alt="logo"/>
        </div>
        <div className="col-lg-7 topheaderalign">
         <div className="row">
            <div className="topnameinlineblock">
              <div className="media">
                <div className="media-body ">
                   <p>Brett Gorman</p>
                </div>
                <div className="media-right">
                  <img src="/icon-phone.png" className="media-object" alt="phone number"/>
                </div>
              </div>
            </div>
            <div className="topphoneinlineblock">
              <div className="media">
                 <div className="media-body topline">
                    <p>0417 355 889</p>
                 </div>
                 <div className="media-right">
                   <img src="/icon-mail.png" className="media-object" alt="email" />
                 </div>
              </div>
            </div>
            <div className="topinlineblock">
              <div className="media">
                 <div className="media-body topline">
                  <a href="mailto:info@vasolargates.com.au" rel="noopener noreferrer" target="_blank">info@vasolargates.com.au</a>
                 </div>
                   <div className="media-right">
                   <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook" className="interactive"></a>
                 </div>
              </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default TopHeader;

