import React from 'react';
import ThumbnailSlider from './ThumbnailSlider';
import OptionsAvailable from './OptionsAvailable';
import PowerOptions from './PowerOptions';
import SolarPowerLight from './SolarPowerLight';
import ServiceArea from './ServiceArea';
import { Link } from 'react-router-dom';

const SingleGate = () => { 
  const thumbimages = [
    'single1.jpg',
    'single2.jpg',
    'single3.jpg',
    'single4.jpg',
    'single5.jpg',
    'single6.jpg',
    'single7.jpg',
    'single8.jpg',
    'single9.jpg',
    'single10.jpg',
    'single11.jpg',
    'single12.jpg',
    'single13.jpg',
    'single14.jpg',
    'single15.jpg',
    'single16.jpg',
    'single17.jpg',
    'single18.png',
  ];

  
  return (
    <>
     <div className="section fp-auto-height">
       <div className="container">
         <div className="greenhead colorhead text-center">
           <img data-src="heading-single.png" className="marginauto img-responsive lazy-loaded" alt="Single Gate" src="heading-single.png" />          
           <h2>Single Solar Gates</h2>
         </div>
         <div className="text-center">
           <p>Most single gates can be automated,  we will have a system to suit your needs. Single gate systems come in solar,  mains , or low voltage powered, they have a variety of options available such as gate alarms, night lights , long range remotes, gsm phone operations , intercom system  to view  and talk to the person at the gate, access control for the gate can be via remotes in car,  visitors buttons, visitors keypads, mobile phone, intercom.</p>
           <p>We mostly use AUSTRALIAN MADE gate system with great tech support and parts backup if ever needed. We use either 12 or 24 volt gate operator system.</p>
           <p>One of our single gate systems 24volt has a locking bar system  running across the bottom of the gate and gives a positive lock of the gate to the closing post, it stops it being pushed open by reasonable force. The units come with a 20 watt solar panel and 9 amp hour batteries, the 12 volt systems can have an opinion of a electronic lock fitted if desired. </p><p>&nbsp;</p>
         </div>
         </div>
         <ThumbnailSlider images={thumbimages} folderName="single" />
         <div className="container"><div className="row"><div className="col-lg-12 col-md-12 col-sm-2 col-xs-2">&nbsp;</div><div className="col-lg-12 col-md-12 col-sm-8 col-xs-8"><center><Link className="anchorprop singlegateoption" to="/options"><img src="icon-single-options.png" className="anchoricon" alt="Options"/><span className="spacer">Options</span></Link></center></div><div className="col-lg-12 col-md-12 col-sm-2 col-xs-2">&nbsp;</div></div></div>
       </div>
       <OptionsAvailable />
       <PowerOptions />
       <SolarPowerLight />
       <ServiceArea />
    </>
  );
};

export default SingleGate;

