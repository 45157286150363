import React from 'react';
import { Link } from 'react-router-dom';

const DiyKits = () => { 
  
  return (
    <>
     <div className="dycontent">
       <div className="container">
       <div className="row">
         <div className="darkyellowhead text-center">
           <img data-src="heading-diy.png" className="marginauto img-responsive" alt="DIY Kits" src="heading-diy.png" />          
           <h2>DIY Kits</h2>
         </div>
         <div className="text-center diysubheader margin30"><p>Outside our service area? Looking for a handy project?<br/>We have a DIY Kits applicable to both Single and Double Gates.<br/>Our DIY Kits ship Australia Wide.</p></div> 
         <div className="text-center margin30"><img data-src="diy-solar-gate-kit.jpg" className="marginauto img-responsive" alt="DIY Kits" src="diy-solar-gate-kit.jpg" /></div>         
         <div class="row margint50">
           <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">&nbsp;</div>
           <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <Link className="diyredbutton" to="/options">Questions with assembly? Phone support available! Contact us today</Link>
           </div>
           <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">&nbsp;</div>
         </div>     
       </div>
        </div>
        </div>
    </>
  );
};

export default DiyKits;

