import React from 'react';

const SolarPowerLight = () => { 
  
  return (
    <>
<div className="section fp-auto-height" id="section8">
  <div className="container">
    <div className="darkyellowhead colorhead text-center">
      <h2>Solar Power Lights</h2>
    </div>
    <div className="text-center margin30">
       We have access to a range of commercial grade solar lights, which are great for domestic and rural applications. Contact us for details!
    </div>
    <div className="row">
      <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 marginb20">&nbsp;</div>
      <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 marginb20">
        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 marginb20">
		<a data-fancybox="solarlightgallery" href="solarlight/remote-solar-light1.jpg">
		<img data-src="solarlight/thumb/remote-solar-light1.jpg" alt="solarlight" className="img-responsive marginauto lazy-loaded" src="solarlight/thumb/remote-solar-light1.jpg" /></a>
        <h5 className="yellowsubhead"></h5>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 marginb20">
            <a data-fancybox="solarlightgallery" href="solarlight/remote-solar-light.jpg">
               <img data-src="solarlight/thumb/remote-solar-light.jpg" alt="Remote solar light" className="img-responsive marginauto lazy-loaded" src="solarlight/thumb/remote-solar-light.jpg" /></a>
            <h5 className="yellowsubhead"></h5>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 marginb20">
            <a data-fancybox="solarlightgallery" href="solarlight/solar-ball-light.jpg">
              <img data-src="solarlight/thumb/solar-ball-light.jpg" alt="Solar Ball Light" className="img-responsive marginauto lazy-loaded" src="solarlight/thumb/solar-ball-light.jpg" />
            </a>
            <h5 className="yellowsubhead"></h5>
        </div>
     </div>
     <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 marginb20">&nbsp;</div>
     </div>
     </div>
     </div>
       </>
  );
};

export default SolarPowerLight;
