import React from 'react';
import Gallery from './Gallery';
const OptionsAvailable = () => { 

    const thumbimages = [
    {image:'options1.jpg',caption: 'Extra remote for car access'},
    {image:'options2.jpg',caption: 'Visitors push button (electronic lock feature available )'},
    {image:'options3.jpg',caption: 'Visitors keypad'},
    {image:'options4.png',caption: 'Medium range remote access 450mt'},
    {image:'options5.png',caption: 'Long range remote access 2km'},
    {image:'options6.png',caption: 'Pe safety beam across the drive.'},
    {image:'options7.jpg',caption: 'Gate alarm for when gate is activated.'},
    {image:'options8.jpg',caption: 'GSM phone operations for gate access away from the gate.'},
    {image:'options9.png',caption: 'Strobe light warning gate closure'},
    {image:'options10.jpg',caption: 'LED night lights'},
    {image:'options11.jpg',caption: 'Intercom audio visual with gate access control, wired or wireless options.'},
  ];
  
    return (
    <>
   <div className="section fp-auto-height" id="section8">
 <div className="container">
	 <div className="darkyellowhead colorhead text-center">
		 <img data-src="heading-options.png" className="marginauto img-responsive" alt="Options Available" src="heading-options.png"/><h2>Options Available</h2>
	 </div>
	 <div className="alertbox">
		 <div className="alertinline"><img className="img-responsive" src="icon-important.png" alt="Icon"/></div>
		 <div className="alertinline">PLEASE NOTE: Not all options available on 12 volt systems. Contact us today to discuss your options.</div>
	 </div>
	 <Gallery images={thumbimages} folderName="options" />
    </div>
</div>
    </>
  );
};

export default OptionsAvailable;

